import domReady from '@wordpress/dom-ready';

domReady(() => {
  const tabContainers = document.querySelectorAll('.team-directory-tabs');

  if (0 < tabContainers.length) {
    tabContainers.forEach(function (tabContainer) {
      /* VARIABLES */
      const tabs = tabContainer.querySelectorAll(
        '.team-directory-tabs__tab-content'
      );
      const tabButtons = tabContainer.querySelectorAll(
        '.team-directory-tabs__heading__item'
      );

      /* HELPERS */

      function initFirstTab() {
        /* display newly selected tab */
        const firstTabButton = tabButtons[0];
        const firstTabContent = tabs[0];
        firstTabContent.removeAttribute('hidden');
        firstTabContent.style.display = 'block';
        firstTabButton.setAttribute('aria-selected', true);
        firstTabButton.classList.add('active');
      }

      function changeDirectoryTab(tabID) {
        /* hide all tabs */
        tabButtons.forEach(function (tabButton) {
          tabButton.setAttribute('aria-selected', false);
          tabButton.classList.remove('active');
        });
        tabs.forEach(function (tab) {
          tab.setAttribute('hidden', true);
          tab.style.display = 'none';
        });

        /* display newly selected tab */
        const relatedTabButton = tabContainer.querySelector(
          '.team-directory-tabs__heading__item[aria-controls="' + tabID + '"'
        );
        const relatedTabPanel = document.getElementById(tabID);
        relatedTabPanel.removeAttribute('hidden');
        relatedTabPanel.style.display = 'block';
        relatedTabButton.setAttribute('aria-selected', true);
        relatedTabButton.classList.add('active');
      }

      /* EVENTS */
      initFirstTab();

      tabButtons.forEach(function (button) {
        button.addEventListener('click', function () {
          changeDirectoryTab(button.getAttribute('aria-controls'));
        });
      });
    });
  }
});
